/// <summary>
/// Generates url: {AppSettings.service.baseUrl}/{this.serviceApi}
// DO NOT append or prepend '/' here!
export enum ServiceType {
  Agencies = 'api/agencies',
  RefreshToken = 'refreshToken',
  Payment = 'api/sorted',
  Property = 'apply/property-info',
  ApiUserProperty = 'api/apply/user/property',
  Apply = 'api/apply/application',
  CoApplicant = 'api/coApplicant/update',
  Group = 'api/group',
  Accept = 'api/bulk/accept',
  Decline = 'api/bulk/decline',
  Draft = 'api/apply/application/draft',
  FileUploadOrDelete = 'api/apply/file',
  Auth = '',
  Terms = 'api/apply/assets',
  MasterProfile = 'api/profile',
  SaveReferencesProfile = 'api/references',
  referenceTool = 'api/questionnaire',
  resendEmail = 'api/user/email',
  SignatureApi = 'api/signature',
  bffMaintenanceRequestId = 'bff/api/maintenance-request',
  maintenanceRequest = 'open/v1/maintenance-request',
  searchProperties = 'properties/search',
  Withdraw = 'api/withdraw',
  RemoteSigning = 'remoteSigning',
  Services = 'v3/sorted/services',
  Disclosure = 'disclosure',
  Callback = 'agencies',
  RDS = 'guest/RDS',
  Equifax = 'api/apply/applicant-verification',
  PaymentAuthKey = 'api/apply/payment/authKey',
  ShortApplication = 'building',
  User = 'api/apply/user',
  ResidentRequest = 'resident-request',
  BranchConfig = 'api/config',
  AdditionalVerification = 'api/additional-verification',
}
